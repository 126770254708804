<template>
  <div>
    <div class="card shadow">
      <div class="pt-4 pb-4 pe-4 ps-4">
        <div class="alert alert-danger" role="alert">
          <b>Attenzione: </b>
          I filtri data fanno riferimento alla data di approvazione del
          movimento
        </div>
        <div class="row">
          <p class="fw-bold ps-1 text-gray-800 ps-3">Periodo:</p>
          <div class="mb-4 col-md-2">
            <SelectInput
              :options="stagioni"
              name="stagione"
              :value="stagioneReports"
              @changeSelect="setstagioneReports($event)"
              placeholder="Stagione"
            />
          </div>
          <div class="mb-4 col-md-3">
            <Datepicker
              placeholder="Data da"
              :format="format"
              :monthChangeOnScroll="false"
              :enableTimePicker="false"
              inputClassName="h-28"
              autoApply
              v-model="data_daReports"
              @update:modelValue="setdata_daReports($event)"
              :disabled="!stagioneReports || isLoading"
              :minDate="minData"
              :maxDate="maxData"
            />
          </div>
          <div class="mb-4 col-md-3">
            <Datepicker
              placeholder="Data a"
              :format="format"
              :monthChangeOnScroll="false"
              :enableTimePicker="false"
              inputClassName="h-28"
              autoApply
              v-model="data_aReports"
              @update:modelValue="setdata_aReports($event)"
              :disabled="!stagioneReports || isLoading"
              :minDate="minData"
              :maxDate="maxData"
            />
          </div>
          <div class="mb-4 col-md-4 align-self-center">
            <input
              class="form-check-input"
              type="checkbox"
              :value="comparaReports"
              :checked="comparaReports == true"
              @click="
                compara = !compara;
                setcomparaReports(compara);
              "
            />
            <label class="fw-bold ps-1 text-gray-800">
              Compara con lo stesso periodo dell'anno precedente</label
            >
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="mb-4 col-12">
            <div class="d-flex">
              <p class="fw-bold ps-1 text-gray-800 ps-3 pe-5">Comitati:</p>
              <input
                class="form-check-input ps-5"
                type="checkbox"
                :value="comitatiAll"
                :checked="comitatiAll == true"
                @click="
                  comitatiAll = !comitatiAll;
                  setComitatiAll(comitatiAll);
                "
              />
              <label class="fw-bold ps-1 text-gray-800">
                Seleziona tutti i comitati</label
              >
            </div>
            <Multiselect
              v-model="selectedComitati"
              :options="comitatiOptions"
              mode="tags"
              :close-on-select="false"
              :searchable="true"
              :create-option="false"
              placeholder="Comitati"
              @change="comitatiAll = false"
            />
            <!-- .multiselect-dropdown -->
          </div>
        </div>
        <hr />
        <div class="row">
          <p class="fw-bold ps-1 text-gray-800 ps-3">Tipologia:</p>
          <div class="mb-4 col-md-3">
            <select class="form-select" aria-label="">
              <option selected>Competenza</option>
            </select>
            <!--  <SelectInput
              name="competenza"
              value="Competenza"
              placeholder="Competenza/Cassa"
            /> -->
          </div>
          <div class="mb-4 col-md-3">
            <SelectInput
              :options="reports_primo_livello"
              name="reports_primo_livello"
              :value="primo_livello"
              @changeSelect="setPrimoLivello($event)"
              placeholder="Tipologia report"
            />
          </div>
          <div class="mb-4 col-md-6">
            <Multiselect
              v-model="selectedSottotipo"
              :options="reports_sottotipo_options"
              mode="tags"
              :close-on-select="false"
              :searchable="true"
              :create-option="false"
              placeholder="Sottotipologia report"
              :disabled="!primo_livello && selectedSottotipo == ''"
            />
          </div>
        </div>
      </div>
      <div class="text-center mb-5">
        <button
          type="button"
          data-bs-toggle=""
          data-bs-target=""
          aria-expanded="false"
          aria-controls=""
          class="badge rounded-pill bg-light text-gray-600"
          @click="$emit('getReportsList')"
        >
          Filtra <i class="bi bi-search fs-6 text-gray-600"> </i>
        </button>
        <span
          type="button"
          data-bs-toggle=""
          data-bs-target=""
          aria-expanded="false"
          aria-controls=""
          class="badge rounded-pill bg-light text-gray-600 ms-4"
          @click.prevent="
            $emit('resetFilters');
            reset();
          "
        >
          <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  ref,
  getCurrentInstance,
  watch,
  onMounted,
  onUpdated,
} from "vue";
import { useStore } from "vuex";

import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import Multiselect from "@vueform/multiselect";
import "vue3-date-time-picker/dist/main.css";
import { format } from "@/composables/formatDate";
import Datepicker from "vue3-date-time-picker";

import services from "../../../axios/dbManag";

export default defineComponent({
  name: "bonifica-anagrafica",
  components: { Multiselect, SelectInput, Datepicker },
  emits: ["getReportsList", "resetFilters"],
  setup() {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const comitati = computed(() => store.getters.getStateFromName("comitati"));
    const comitatiOptions = ref([]);
    const keys = ref("c");
    store.dispatch("setStoreData", {
      keys: { keys: keys.value },
      apiLink: globalApi.COMBOLIST_GET,
    });

    const selectedComitati = ref([]);
    const selectedSottotipo = ref([]);

    const reports_primo_livello = computed(() =>
      store.getters.getStateFromName("reports_primo_livello")
    );

    store
      .dispatch("setStoreData", {
        apiLink: globalApi.REPORTS_LIVELLI_LIST,
      })
      .then(() => setPrimoLivello(null));

    const reports_sottotipo = ref([]);
    const reports_sottotipo_options = ref([]);
    const setPrimoLivello = (event) => {
      primo_livello.value = event;
      // reports_sottotipo.value.splice(0, reports_sottotipo.value.length);
      reports_sottotipo_options.value.splice(
        0,
        reports_sottotipo_options.value.length
      );
      reports_primo_livello.value.forEach((element) => {
        if (parseInt(event) == element.id_tipo_report) {
          reports_sottotipo.value = element.sottotipo_report;
        }
      });
      reports_sottotipo.value.forEach((element) => {
        let tag = {
          value: element.id_sottotipo_report,
          label: element.sottotipo_report,
        };
        reports_sottotipo_options.value.push(tag);
      });
    };

    const primo_livello = ref(null);
    // setPrimoLivello(0);
    // onMounted(() => );
    const isLoading = ref(false);
    const minData = ref(null);
    const maxData = ref(null);

    const stagioneReports = computed(() => store.getters.stagioneReports);
    const setstagioneReports = (stagione) => {
      store.commit("setstagioneReports", stagione);
      store.commit("setdata_daReports", null);
      store.commit("setdata_aReports", null);
      isLoading.value = true;
      return services.dbManag
        .post("/reportistica/economato/date/stagione/view", {
          stagione,
        })
        .then((res) => {
          isLoading.value = false;
          store.commit(
            "setdata_daReports",
            res.data.stagione_date_range[0].data_inizio
          );
          store.commit(
            "setdata_aReports",
            new Date() >= new Date(res.data.stagione_date_range[0].data_fine)
              ? res.data.stagione_date_range[0].data_fine
              : new Date()
          );
          minData.value = res.data.stagione_date_range[0].data_inizio;
          maxData.value = res.data.stagione_date_range[0].data_fine;

          return res;
        })
        .catch((e) => {
          return e.response;
        });
    };

    const comparaReports = computed(() => store.getters.comparaReports);
    const setcomparaReports = (event) => {
      store.commit("setcomparaReports", event);
    };

    const data_daReports = computed(() => store.getters.data_daReports);
    const setdata_daReports = (event) => {
      store.commit("setdata_daReports", event);
    };

    const data_aReports = computed(() => store.getters.data_aReports);
    const setdata_aReports = (event) => {
      store.commit("setdata_aReports", event);
    };

    const tuttiComitati = ref([]);

    watch(comitati, () => {
      comitati.value.forEach((element) => {
        tuttiComitati.value.push(element.id);
        comitati.value.push(element);
        let tag = {
          value: element.id,
          label: element.label,
        };
        comitatiOptions.value.push(tag);
      });
    });

    const ids_comitatiReports = computed(
      () => store.getters.ids_comitatiReports
    );
    const setids_comitatiReports = (event) => {
      store.commit("setids_comitatiReports", event);
    };
    const ids_comitati_string = ref("");
    watch(selectedComitati, () => {
      ids_comitati_string.value = "";
      selectedComitati.value.forEach((element) => {
        ids_comitati_string.value = ids_comitati_string.value + element + ",";
      });
      setids_comitatiReports(ids_comitati_string.value);
    });

    const ids_livelliReports = computed(() => store.getters.ids_livelliReports);
    const setids_livelliReports = (event) => {
      store.commit("setids_livelliReports", event);
    };

    const ids_livelli_string = ref("");
    watch(selectedSottotipo, () => {
      ids_livelli_string.value = "";
      selectedSottotipo.value.forEach((element) => {
        ids_livelli_string.value = ids_livelli_string.value + element + ",";
      });
      setids_livelliReports(ids_livelli_string.value);
    });

    const compara = ref(false);

    const reset = () => {
      selectedSottotipo.value = [];
      selectedComitati.value = [];
      compara.value = false;
      primo_livello.value = null;
      comitatiAll.value = false;
    };

    const comitatiAll = ref(false);

    const setComitatiAll = (event) => {
      if (event == true) {
        selectedComitati.value = tuttiComitati.value;
      } else selectedComitati.value = [];
    };

    const stagioni = computed(() => store.getters.stagioni);

    return {
      comitati,
      reports_primo_livello,
      // reports_sottotipo,
      reports_sottotipo_options,
      comitatiOptions,
      stagioni,

      format,
      setPrimoLivello,
      setstagioneReports,
      setcomparaReports,
      setids_comitatiReports,
      setids_livelliReports,
      setdata_daReports,
      setdata_aReports,
      reset,
      compara,

      primo_livello,
      selectedComitati,
      selectedSottotipo,
      stagioneReports,
      comparaReports,
      ids_comitatiReports,
      ids_livelliReports,
      data_daReports,
      data_aReports,

      isLoading,
      minData,
      maxData,
      setComitatiAll,
      comitatiAll,
    };
  },
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped></style>
