<template>
  <div>
    <FilterReport
      @getReportsList="getReportsList"
      @resetFilters="resetFilters"
    />
    <TableReport :startSearch="startSearch" :msgError="msgError" />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  getCurrentInstance,
  computed,
  ref,
} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import FilterReport from "@/components/components-fit/report/FilterReport.vue";
import TableReport from "@/components/components-fit/report/TableReport.vue";

import { useStore } from "vuex";

export default defineComponent({
  name: "bonifica-anagrafiche",
  components: {
    FilterReport,
    TableReport,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Report Economato", []);
    });

    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const stagione = computed(() => store.getters.stagioneReports);
    const compara = computed(() => store.getters.comparaReports);
    const ids_comitati = computed(() => store.getters.ids_comitatiReports);
    const ids_livelli = computed(() => store.getters.ids_livelliReports);
    const data_da = computed(() => store.getters.data_daReports);
    const data_a = computed(() => store.getters.data_aReports);

    const msgError = ref("");
    const startSearch = ref(false);
    const getReportsList = async () => {
      if (
        stagione.value &&
        ids_comitati.value &&
        ids_livelli.value &&
        data_da.value &&
        data_a.value
      ) {
        msgError.value = "";
        startSearch.value = true;
        await store.dispatch("setStoreListData", {
          keys: {
            stagione: stagione.value,
            compara: compara.value,
            ids_comitati: ids_comitati.value,
            ids_livelli: ids_livelli.value,
            data_da: data_da.value,
            data_a: data_a.value,
          },
          apiLink: globalApi.REPORTS_LIST,
          itemName: "reports_list",
        });
      } else {
        msgError.value =
          "Per visualizzare i report è necessario valorizzare tutti i parametri";
      }
    };
    //getReportsList();

    const resetFilters = () => {
      store.commit("resetReports");
      getReportsList();
      startSearch.value = false;
      msgError.value = "";
    };

    return { getReportsList, resetFilters, startSearch, msgError };
  },
});
</script>
