<template>
  <div v-if="startSearch && msgError == ''">
    <div class="text-end" style="margin-right: 19px"></div>
    <br />
    <div class="mx-auto my-auto text-center mt-5" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="status != 200 && status != 204 && loaded"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div
      v-else-if="!reports_list[0] && (status == 200 || status == 204)"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <div
        id="accordionExample"
        class="bg-secondary fw-bold rounded mb-7"
        v-if="reports_list[0].causali"
      >
        <div class="bg-secondary p-0 fw-bold fs-6 rounded" id="headingOne">
          <button
            style="background-color: #e3e5ee !important"
            class="accordion-button blueFit collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            Causali interessate
          </button>

          <div
            id="collapseOne"
            class="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div
                class="blueFit fs-8"
                v-for="(item, index) in reports_list[0].causali"
                :key="index"
              >
                <span class="">{{ item.causale }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="
          reports_list[0].anno_corrente ||
          reports_list[0].anno_precedente ||
          reports_list[0].anno_precedente_completo ||
          reports_list[0].variazione
        "
      >
        <div class="table-responsive card shadow">
          <table class="table gy-3 gs-7 align-middle">
            <thead>
              <tr
                class="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200"
              >
                <th
                  v-if="reports_list[0].anno_corrente"
                  class="border-report ps-5"
                  scope="col"
                >
                  Anno corrente
                </th>
                <th
                  v-if="reports_list[0].anno_precedente"
                  class="border-report ps-5"
                  scope="col"
                >
                  Anno precedente
                </th>
                <th
                  v-if="reports_list[0].anno_precedente_completo"
                  class="border-report ps-5"
                  scope="col"
                >
                  Anno precedente completo
                </th>
                <th
                  v-if="reports_list[0].variazione"
                  class="border-report ps-5"
                  scope="col"
                >
                  Variazione
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  class="border-report ps-2"
                  v-if="reports_list[0].anno_corrente"
                >
                  <div class="card shadow">
                    <table class="table table-striped gy-3 gs-7 align-middle">
                      <thead>
                        <tr>
                          <th></th>
                          <th class="text-end">unità</th>
                          <th class="text-end">€</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(report, index) in reports_list[0]
                            .anno_corrente"
                          :key="index"
                        >
                          <td
                            class="text-nowrap"
                            :style="
                              report.circolo == 'Totale'
                                ? 'border-top: 1px solid #bac9d1;'
                                : ''
                            "
                          >
                            {{ report.circolo }}
                          </td>
                          <td
                            class="text-end"
                            :style="
                              report.circolo == 'Totale'
                                ? 'border-top: 1px solid #bac9d1;'
                                : ''
                            "
                          >
                            {{ report.numero_quote }}
                          </td>
                          <td
                            class="text-end"
                            :style="
                              report.circolo == 'Totale'
                                ? 'border-top: 1px solid #bac9d1;'
                                : ''
                            "
                          >
                            {{ report.importo_tot }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
                <td
                  class="border-report"
                  v-if="reports_list[0].anno_precedente"
                >
                  <div class="card shadow">
                    <table class="table table-striped gy-3 gs-7 align-middle">
                      <thead>
                        <tr>
                          <th></th>
                          <th class="text-end">unità</th>
                          <th class="text-end">€</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(report, index) in reports_list[0]
                            .anno_precedente"
                          :key="index"
                        >
                          <td
                            class="text-nowrap"
                            :style="
                              report.circolo == 'Totale'
                                ? 'border-top: 1px solid #bac9d1;'
                                : ''
                            "
                          >
                            {{ report.circolo }}
                          </td>
                          <td
                            class="text-end"
                            :style="
                              report.circolo == 'Totale'
                                ? 'border-top: 1px solid #bac9d1;'
                                : ''
                            "
                          >
                            {{ report.numero_quote }}
                          </td>
                          <td
                            class="text-end"
                            :style="
                              report.circolo == 'Totale'
                                ? 'border-top: 1px solid #bac9d1;'
                                : ''
                            "
                          >
                            {{ report.importo_tot }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
                <td
                  class="border-report"
                  v-if="reports_list[0].anno_precedente_completo"
                >
                  <div class="card shadow">
                    <table class="table table-striped gy-3 gs-7 align-middle">
                      <thead>
                        <tr>
                          <th></th>
                          <th class="text-end">unità</th>
                          <th class="text-end">€</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(report, index) in reports_list[0]
                            .anno_precedente_completo"
                          :key="index"
                        >
                          <td
                            class="text-nowrap"
                            :style="
                              report.circolo == 'Totale'
                                ? 'border-top: 1px solid #bac9d1;'
                                : ''
                            "
                          >
                            {{ report.circolo }}
                          </td>
                          <td
                            class="text-end"
                            :style="
                              report.circolo == 'Totale'
                                ? 'border-top: 1px solid #bac9d1;'
                                : ''
                            "
                          >
                            {{ report.numero_quote }}
                          </td>
                          <td
                            class="text-end"
                            :style="
                              report.circolo == 'Totale'
                                ? 'border-top: 1px solid #bac9d1;'
                                : ''
                            "
                          >
                            {{ report.importo_tot }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
                <td
                  class="border-report pe-2"
                  v-if="reports_list[0].variazione"
                >
                  <div class="card shadow">
                    <table class="table table-striped gy-3 gs-7 align-middle">
                      <thead>
                        <tr>
                          <th></th>
                          <th class="text-end">unità%</th>
                          <th class="text-end">importo%</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(report, index) in reports_list[0].variazione"
                          :key="index"
                        >
                          <td
                            class="text-nowrap"
                            :style="
                              report.circolo == 'Totale'
                                ? 'border-top: 1px solid #bac9d1;'
                                : ''
                            "
                          >
                            {{ report.circolo }}
                          </td>
                          <td
                            :class="
                              report.numero_quote_var.includes('-')
                                ? 'text-danger'
                                : ''
                            "
                            class="text-end"
                            :style="
                              report.circolo == 'Totale'
                                ? 'border-top: 1px solid #bac9d1;'
                                : ''
                            "
                          >
                            {{ report.numero_quote_var }}
                          </td>
                          <td
                            :class="
                              report.importo_tot_var.includes('-')
                                ? 'text-danger'
                                : ''
                            "
                            class="text-end"
                            :style="
                              report.circolo == 'Totale'
                                ? 'border-top: 1px solid #bac9d1;'
                                : ''
                            "
                          >
                            {{ report.importo_tot_var }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
              <!-- </template> -->
            </tbody>
          </table>
        </div>
        <div class="row pt-5">
          <div class="text-center">
            <button
              type="button"
              class="btn btn-light text-gray-700 fs-5"
              data-bs-toggle="modal"
              data-bs-target="#modal_esporta_report"
            >
              <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta
              report
            </button>
          </div>
        </div>
        <ModalEsportaReport />
      </div>
      <div v-else class="text-center">
        <span class="text-gray-700 ps-3 fs-2">
          Attenzione! Nessun report disponibile.
        </span>
      </div>
    </div>
  </div>
  <div v-if="msgError != ''">
    <div class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5">
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-4">
        {{ msgError }}
      </span>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed } from "vue";
import isEnabled from "@/composables/isEnabled.js";
import ModalEsportaReport from "./ModalEsportaReport.vue";

export default {
  name: "table-privacy-tes-soc",
  components: { ModalEsportaReport },
  emits: ["getPrivacyTesSocietaList", "resetFilters"],
  props: {
    startSearch: {
      type: Boolean,
    },
    msgError: {
      type: String,
    },
  },
  setup() {
    const store = useStore();

    const tableHeader = ref([
      {
        name: "Codice Fiscale",
        key: "cod_fisc",
      },
      {
        name: "Tesserato",
        key: "nominativo",
      },
      {
        name: "Paragrafo 1.A",
        key: "privacy_1",
        sortable: false,
      },
      {
        name: "Paragrafo 1.B",
        key: "privacy_2",
        sortable: false,
      },
      {
        name: "Paragrafo 1.C",
        key: "privacy_3",
        sortable: false,
      },
    ]);

    return {
      isEnabled,
      reports_list: computed(() =>
        store.getters.getStateFromName("resultsreports_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedreports_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordreports_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusreports_list")
      ),
      tableHeader,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
    };
  },
};
</script>

<style scoped>
.border-report {
  border-right: 2px solid #f0f2f5;
}
.shadow {
  box-shadow: 0 0.1rem 0.6rem 0.1rem rgb(0 0 0 / 5%) !important;
}
.table-responsive {
  min-height: 0px !important;
}

.accordion-button {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.accordion-button:not(.collapsed) {
  border-bottom-left-radius: initial !important;
  border-bottom-right-radius: initial !important;
}
</style>
